import React from "react";
import Grid from "@mui/material/Grid";

const About = () => {
  return (
    <div className="home container">
      <h1>About</h1>
      <Grid container spacing={2} className="mt-5 home-grid mb-4">
        <Grid item xs={5} className="p-4">
          <div>
            <img src="sun_panels3.jpg" width="100%" className="home-image" />
          </div>
          <hr className="mx-5 my-4" />
          <h4>
            Ето и някой от обектите по които Методиев и Синове – Електропулс
            ЕООД са работили
          </h4>
          <div className="inner-home-text">
            <ul>
              <li>
                <h4>„КЦМ“ АД, Пловдив</h4>
                <div>
                  Aвтоматизация на производството в цех „Мокро извличане цинк и
                  кадмий“
                </div>
              </li>
              <li>
                <h4>Тунел „Ечемишка“</h4>
                <div>Преработка на трафопостове</div>
              </li>
              <li>
                <h4>„Електростомана 2004“ АД, Карлово</h4>
                <div>Изграждане на поточна линия</div>
              </li>
              <li>
                <h4>„Биовет“ АД, Пещера</h4>
                <div>Разширение на „Биовет“ – цех за ферментация</div>
              </li>
            </ul>
          </div>
        </Grid>
        <Grid item xs={7} className="text-justify">
          <h3>
            Методиев и Синове – Електропулс ЕООД е основано през 2017 г. в гр.
            Пловдив.
          </h3>
          <hr className="mx-5" />
          <div className="inner-home-text">
            Основният предмет на дейност на фирмата е проектиране, изграждане и
            поддръжка на електроинсталации, ел. прибори, уреди и съоръжения.
            По-конкретно дружеството изпълнява следните дейности: ел. оборудване
            на промишлени и жилищни сгради, монтаж на БКТП, МКТП, преустройство
            на съществуващи трафопостове, структурно окабеляване на ниско
            напрежение, информационни и оптични кабели, КИП и автоматизация,
            пожароизвестяване, изграждане на фотоволтаични централи, работа на
            екстремни височини и др..
          </div>
          <hr className="mx-5 my-4" />
          <div className="text-center">
            <img
              src="cabel_installation.jpg"
              width="80%"
              className="home-image"
              alt="installation"
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default About;
