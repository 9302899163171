import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import { Form, FormGroup, Input, Row, Col } from "reactstrap";
import validator from "validator";
import Swal from "sweetalert2";
import axios from "axios";
import PhoneIcon from "@mui/icons-material/Phone";
const Contacts = () => {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [moreInfo, setMoreInfo] = useState("");
  const [subject, setSubject] = useState("");
  const [email, setEmail] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();
    if (name.length > 0 && name.length > 0) {
      var bodyFormData = new FormData();
      bodyFormData.append("email", "viktor.mtdiev@gmail.com");
      bodyFormData.append(
        "body",
        `${
          "Здравейте <br>" +
          "Имате съобщение от: " +
          name +
          "<br> Тел. Номер: " +
          phone +
          "<br> Детайли: " +
          moreInfo
        }`
      );
      axios
        .post(
          "https://metodievandsons-electropulse.bg/ws/webform.php",
          bodyFormData,
          {
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
          }
        )
        .then(() => {
          Swal.fire("Успешно пращане на мейл-а", "success");
        })
        .catch((e) => {
          Swal.fire("Грешка при пращането на мейл-а", "error");
        });
    }
  };

  return (
    <div className="container my-5 home">
      <Grid
        container
        spacing={0}
        className="mt-5 home-grid"
        style={{ justifyContent: "center" }}
      >
        <Grid
          item
          xs={12}
          lg={5}
          md={12}
          lassName="p-4"
          style={{ justifyContent: "center" }}
        >
          <div className="contact-form">
            <h4 className="text-center" style={{ textTransform: "uppercase" }}>
              Контакти
            </h4>
            <hr className="mx-5 mt-3 mb-4" />

            <Form
              className="m-auto mt-2 text-start"
              onSubmit={(e) => handleSubmit(e)}
            >
              <FormGroup>
                <Row>
                  <Col>
                    <strong className="font-weight-bold">Вашето Име</strong>
                    <Input
                      name="email"
                      id="exampleEmail"
                      placeholder="Име и Фамилия"
                      className="m-auto mt-1 mb-3 gold-border"
                      onChange={(e) => setName(e.target.value)}
                      value={name}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <strong className=" font-weight-bold">
                      Телефонен Номер
                    </strong>
                    <Input
                      type="phone"
                      name="phone"
                      id="examplePassword"
                      className="m-auto mt-1 mb-3 gold-border"
                      onChange={(e) => setPhone(e.target.value)}
                      value={phone}
                    />
                    {!validator.isMobilePhone(phone) && phone.length > 0 && (
                      <strong style={{ color: "red" }}>
                        Въведете правилен телефонен номер
                      </strong>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <strong className=" font-weight-bold">Е-mail</strong>
                    <Input
                      type="email"
                      name="email"
                      id="examplePassword"
                      className="m-auto mt-1 mb-3 gold-border"
                      onChange={(e) => setEmail(e.target.value)}
                      value={email}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <strong className="font-weight-bold">Относно</strong>
                    <Input
                      type="phone"
                      name="phone"
                      id="examplePassword"
                      className="m-auto mt-1 mb-3 gold-border"
                      onChange={(e) => setSubject(e.target.value)}
                      value={subject}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <strong className="my-4 font-weight-bold">Съобщение</strong>
                    <Input
                      type="textarea"
                      name="text"
                      id="exampleText"
                      className="m-auto mt-1 mb-3 gold-border"
                      value={moreInfo}
                      onChange={(e) => setMoreInfo(e.target.value)}
                    />
                  </Col>
                </Row>
              </FormGroup>
              <button className="contact-btn">Изпращане</button>
              <hr className="mx-5 my-4" />
              <div>
                <span className="mx-4">
                  <strong>Или позвънете на:</strong>
                  <a href={`tel:0877642589`} className="ms-3">
                    <PhoneIcon className="me-2" /> 0877642589
                  </a>
                </span>
              </div>
            </Form>
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          lg={5}
          md={12}
          className="pt-2 contacts-photo"
          style={{ marginTop: 1, marginRight: -5 }}
        >
          <div>
            <img
              src="background.jpg"
              className="home-image"
              alt="sun-panel"
              width="104%"
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default Contacts;
