import React from "react";
import Grid from "@mui/material/Grid";

const Home = () => {
  return (
    <div className="home container">
      <h1 style={{ textTransform: "uppercase" }}>
        Методиев и Синове - Електропулс
      </h1>
      <Grid container spacing={0} className="mt-5 home-grid mb-4">
        <Grid item xs={12} lg={6} md={12} className="text-justify">
          <h4 className="text-center" style={{ textTransform: "uppercase" }}>
            За Нас
          </h4>
          <hr className="mx-5" />
          <div className="inner-home-text">
            „Методиев и синове – Електропулс“ ЕООД е основано през 2017 г. в
            град Пловдив, предоставя услуги в сферата на електромонтажа и има
            доказан опит на сигурен партньор при изпълнението на проекти,
            включително като подизпълнител на някои от лидерите на пазара в
            България в областта на електротехниката. Компанията има история на
            коректно и точно изпълнение на поетите договорни задължения по
            проекти в рамките на бюджета, както и по отношение на качество и
            срок. Дружеството е регистрирано в Централен Професионален Регистър
            на Строителя.
          </div>
          <hr className="mx-5 my-4" />
          <div className="text-center">
            <img src="sun_panels3.jpg" width="80%" className="home-image" />
          </div>
          <hr className="mx-5 my-4" />
          <div className="inner-home-text">
            Нашият най-ценен ресурс са служителите, за което свидетелстват и
            дългосрочните взаимоотношения, които развиваме с нашите клиенти,
            доставчици и партньори. „Методиев и синове – Електропулс“ ЕООД
            разполага със сплотен колектив, готов за всякакви предизвикателства,
            който постепенно увеличаваме във връзка с разширяването на дейността
            ни, без да спираме да поставяме взаимното уважение и безопасността
            на работното място като основни приоритети при изграждането на екип.
          </div>
        </Grid>
        <Grid item xs={12} lg={6} md={12} className="p-4 text-justify">
          <div>
            <img src="color_logo2.svg" width="100%" className="py-5" />
          </div>
          <hr className="mx-5" />
          <h5>
            <div className="inner-home-text">
              Основен предмет на дейност на дружеството е проектиране,
              изграждане и поддръжка на електроинсталации, ел. прибори, уреди и
              съоръжения. „Методиев и синове – Електропулс“ ЕООД стартира
              дейността си с изграждане, въвеждане в експлоатация, поддръжка и
              ремонт на външни електрозахранвания и вътрешни електроинсталации,
              както и мрежи с ниско и средно напрежение. Компанията развива и
              активна дейност по електрообслужване за промишлени нужди - монтаж
              на електрообзавеждане и автоматизация на обекти, линии и
              съоръжения за индустрията. През последните години дружеството
              поставя силен фокус в своята дейност в областта на възобновяемите
              енергийни източници като участва активно в изграждането на
              фотоволтаични паркове и на автономни системи за собствено
              потребление.
            </div>
            <hr className="mx-5 my-4" />
            <div className="text-center">
              <img
                src="cabel_installation.jpg"
                width="80%"
                className="home-image"
                alt="installation"
              />
            </div>
          </h5>
        </Grid>
      </Grid>
    </div>
  );
};

export default Home;
