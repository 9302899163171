import React from "react";
import Grid from "@mui/material/Grid";

const Services = () => {
  return (
    <div className="home container">
      <h1 className="text-center" style={{ textTransform: "uppercase" }}>
        Услуги, които предлагаме
      </h1>
      <Grid container spacing={0} className="mt-5 home-grid mb-4">
        <Grid item xs={12} lg={8} md={7} className="services-margin">
          <div className="inner-home-text text-start">
            <ul className="services">
              <li>
                <div>
                  Изграждане на електрически инсталации и монтаж на
                  електрооборудване в жилищни и промишлени сгради;
                </div>
              </li>
              <li>
                <div>
                  Инсталация на мрежи с ниско напрежение и структурно
                  окабеляване за информационни инфраструктури, обединяващи
                  различни по функции и предназначение подсистеми за
                  информационен обмен - системи за безопасност и контрол,
                  видеонаблюдение, пожароизвестяване и др.;
                </div>
              </li>
              <li>
                <div>
                  Последваща поддръжка на изградени системи, както и надграждане
                  на вече съществуващи структурни кабелни системи;
                </div>
              </li>
              <li>
                <div>
                  Полагане на силови кабели за ниско и средно напрежение,
                  информационни и оптични кабели;
                </div>
              </li>
              <li>
                <div>
                  Монтаж на бетонови комплектни трансформаторни постове (БКТП) и
                  метални комплектни трансформаторни постове (МКТП), както и
                  преустройство на съществуващи трансформаторни постове;
                </div>
              </li>
              <li>
                <div>
                  Монтаж и въвеждане в експлоатация на контролно-измервателни
                  прибори (КИП) и системи за автоматизация
                </div>
              </li>
              <li>
                <div>
                  Монтаж на осветление за жилищни, търговски, обществени и
                  индустриални сгради, както и на улично и парково осветление;
                </div>
              </li>
              <li>
                <div>
                  Изграждане на фотоволтаични системи – както наземни и покривни
                  централи, така и автономни фотоволтаични системи;
                </div>
              </li>
              <li>
                <div>
                  Изпълнение на други електромонтажни работи, включително на
                  екстремни височини.
                </div>
              </li>
            </ul>
          </div>
        </Grid>
        <Grid item xs={12} lg={4} md={5} className="pt-2">
          <div>
            <img src="sun_panels2.jpg" width="100%" className="home-image" />
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default Services;
