import logo from "./logo.svg";
import "./App.css";
import "./style.scss";
import Header from "./components/header/Header.js";
import "bootstrap/dist/css/bootstrap.css";
import Layout from "./components/layout/Layout";
function App() {
  return <Layout />;
}

export default App;
