import Header from "../header/Header.js";
import Footer from "../footer/Footer.js";
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "../home/Home.js";
import Services from "../services/Services.js";
import About from "../about/About.js";
import Contacts from "../contacts/Contacts";
const Layout = (props) => {
  return (
    <div>
      <Header />
      <Router>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/About" element={<About />} />
          <Route path="/Services" element={<Services />} />
          <Route path="/Contacts" element={<Contacts />} />
        </Routes>
      </Router>
      <div>{props.children}</div>
      <Footer />
    </div>
  );
};

export default Layout;
