import React, { useEffect, useState } from "react";

const Header = () => {
  const [activeClass, setActiveClass] = useState("");

  useEffect(() => {
    if (typeof window !== "undefined") {
      setActiveClass("");
    }
  }, []);

  return (
    <div>
      <div className="header-bg"></div>
      <div className="header">
        <img src="/black-logo.svg" className="logo py-2" />
        <div className="float-end navigation">
          <a
            className={window.location.pathname === "/" ? "active" : ""}
            href="/"
          >
            Начало
          </a>
          {/* <a
            className={window.location.pathname === "/About" ? "active" : ""}
            href="/About"
          >
            About
          </a> */}
          <a
            className={window.location.pathname === "/Services" ? "active" : ""}
            href="/Services"
          >
            Услуги
          </a>
          <a
            className={
              window.location.pathname === "/Contacts" ? "active" : "'"
            }
            href="/Contacts"
          >
            Контакти
          </a>
          {/* <a>EN</a>
          <a>BG</a> */}
        </div>
      </div>
    </div>
  );
};

export default Header;
