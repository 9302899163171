import React from "react";
import Grid from "@mui/material/Grid";
import MailOutlineIcon from "@mui/icons-material/MailOutline";

const Footer = () => {
  return (
    <div className="footer">
      <div className="container">
        <Grid container spacing={2} className="home-grid">
          <Grid item xs={12} lg={4} md={12} className="p-3 pb-1">
            <div className="text-center">
              <img
                src="color_logo2.svg"
                width="30%"
                style={{ display: "flex", alignItems: "start" }}
              />
            </div>
            <small style={{ fontSize: 12, color: "grey" }}>
              © 2022 Методиев и синове - Електропулс ЕООД
            </small>
          </Grid>
          <Grid
            item
            lg={3}
            xs={12}
            md={12}
            className="footer-contancts p-3 pb-1"
          >
            <div className="footer-title">Контакти: </div>
            <div>
              <span>
                <a href={`mailto:melectropulse@gmail.com`}>
                  <MailOutlineIcon className="me-2" /> melectropulse@gmail.com
                </a>
              </span>
            </div>
          </Grid>
          <Grid item xs={5} lg={5} xs={12} md={12} className=" p-3 pb-1">
            <div className="footer-title">Адрес: </div>
            <div>
              <span>Бул. Свобода 3, </span>
              <span className="mx-4">Пловдив, </span>
              <span>България</span>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default Footer;
